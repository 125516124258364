import Link from 'next/link';
import { PublicPageLayout } from 'components/layouts/public-page';
import {
  ContentArea,
  ContentAreaHeader,
} from 'design-system/components/content-area';
import { Anchor } from 'design-system/components/anchor';
import { VStack } from 'design-system/components/stack';

export default function Custom404() {
  return (
    <PublicPageLayout>
      <ContentArea style={{ margin: '2em 10%' }}>
        <VStack>
          <ContentAreaHeader>404 - Page Not Found</ContentAreaHeader>
          <Anchor as={Link} href="/" $alternative>
            ← Back to Home
          </Anchor>
        </VStack>
      </ContentArea>
    </PublicPageLayout>
  );
}
