/**
 * ContentArea is part of the Content* components that are the base container
 * for page content in the 2021-era user interface.
 *
 * View the Design System storybook stories to see visual examples.
 *
 * Expected usage:
 *
 * ```
 * <ContentArea>
 *   <ContentAreaHeader>A Partname Name</ContentAreaHeader>
 *   <TabPanelsContainer>
 *     <TabLinks tabs={localTabs} />
 *   <ContentCard>
 *   <ContentCardHeading><Icon /> Details</ContentCardHeading>
 *   <ContentCardCopy>
 *      A single paragraph of text.
 *   </ContentCardCopy>
 *   <ContentCardCopy>
 *     If you want more content, use multiple ContentCardCopy elements.
 *   </ContentCardCopy>
 * </ContentArea>
 * ```
 **/
import { memo, useState } from 'react';
import styled from 'styled-components';
import * as mq from '../theme/media-queries';
import { SearchIcon } from '../icons/search';

const SearchForm = styled.form`
  ${mq.only_sm} {
    width: 100%;
  }
  border: 1px solid transparent;
  border-radius: 2em;
  position: relative;
  transition: all 200ms ease-in;
  height: 2.125em;
  overflow: hidden;
  width: 2em;
  border-color: grey;
  width: 20em;

  svg {
    position: absolute;
    right: 0.5em;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    pointer-events: none;
  }

  input {
    // because bootstrap
    outline: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    // 3em covers the svg on the right side
    padding: 0 3em 0 1em;

    $:hover,
    $:focus {
      border: 0 solid transparent !important;
    }
  }
`;
export const SearchInput = memo(function SearchInput({
  placeholder,
  value,
  onChange,
  onSubmit,
}: {
  placeholder?: string;
  value?: string;
  onChange?(value: string): void;
  onSubmit?(value: string): void;
}) {
  const [internalValue, setValue] = useState('');
  return (
    <SearchForm
      onSubmit={
        onSubmit
          ? (event) => {
              event.preventDefault();
              onSubmit(value || internalValue);
            }
          : undefined
      }
    >
      <input
        type="search"
        name="search"
        aria-label="Search"
        placeholder={placeholder}
        value={value || internalValue}
        onChange={function internalOnChange(event) {
          onChange
            ? onChange(event.target.value)
            : setValue(event.target.value);
        }}
      />
      <SearchIcon />
    </SearchForm>
  );
});

export const ContentArea = styled.main`
  background-image: linear-gradient(180deg, #ffffff 0%, #fcfbfd 100%);
  border: none;
  padding: 1.5em 1em;

  ${mq.md} {
    padding: 2em;
    border-radius: 32px;
    border: 4px solid #ece7fd;
  }
`;

export const ContentAreaHeader = styled.h1`
  display: inline-flex;
  align-items: center;
  margin: 0;
  font-weight: 700;
  line-height: 1.33;
  font-size: 26px;

  svg:first-child {
    margin-right: 0.16666em;
  }

  ${mq.md} {
    font-size: 36px;
  }
`;
